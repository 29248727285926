<template>
  <div class="container">
    <section class="section section-one">
      <div class="wrapper">
        <div class="titelwrapper">
          <h1>Villa Riffig</h1>
        </div>
        <div class="spaltenwrapper">
          <div class="erstesdrittel">
          </div>
          <div class="zweitesdrittel">
            <p>Die Villa Riffig ist ein Ort zum Sein, bei dem die Freude und das Glücklichsein an erster Stelle stehen. Hier kommen wir zusammen, um in vielfältigster Weise dem Leben zu begegnen, in Beziehung zu gehen, unser Potenzial zu leben, zu lernen und zu entdecken, zu spielen und zu lachen. Wir experimentieren, erschaffen Projekte und geniessen gemeinsam den wunderbaren Ort mit all seinen Möglichkeiten.</p>
          </div>
          <div class="drittesdrittel">
            <p>Davon ausgehend, dass jeder Mensch einzigartig ist und es gilt, diese Einzigartigkeit zu wahren, schaffen wir einen sicheren und geschützten Raum, wo sich jeder seinen Bedürfnissen, seinem Tempo und seinem Potenzial entsprechend entfalten darf. Wir begleiten Menschen auf dem Weg zu frei denkenden, frei fühlenden und frei handelnden, selbständigen und verantwortungsbewussten Persönlichkeiten. Dies verstehen wir als Basis für ein Miteinander in Frieden mit den Menschen, der Natur und unserer Welt.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-two">
    <div class="wrapper">
      <div class="titelwrapper">
        <h1>Konzept</h1>
      </div>
      <div class="spaltenwrapper">
        <div class="erstesdrittel">
        </div>
        <div class="zweitesdrittel">
          <p>Die Villa Riffig ist ein Ort zum Sein, bei dem die Freude und das Glücklichsein an erster Stelle stehen. Hier kommen wir zusammen, um in vielfältigster Weise dem Leben zu begegnen, in Beziehung zu gehen, unser Potenzial zu leben, zu lernen und zu entdecken, zu spielen und zu lachen. Wir experimentieren, erschaffen Projekte und geniessen gemeinsam den wunderbaren Ort mit all seinen Möglichkeiten.</p>
        </div>
        <div class="drittesdrittel">
          <p>Lese hier das Konzept der Villa Riffig:<br /><a target="_blank" href="https://villariffig.ch/konzept-villa-riffig-1-3-2024.pdf">→ konzept-villa-riffig-1.3.2024</a></p>
        </div>
      </div>
    </div>
    </section>
    <section class="section section-three">
      <div class="wrapper">
        <div class="titelwrapper">
          <h1>Der Ort</h1>
        </div>
        <div class="spaltenwrapper">
          <div class="erstesdrittel">
          </div>
          <div class="zweitesdrittel">
            <p>Die Villa Riffig ist ein älteres dreistöckiges Bauernhaus mit grossem Garten. Die Liegenschaft liegt direkt am idyllischen Naherholungsgebiet Riffigweiher und am Eingang zum Riffigwald. Gut erschlossen beim Bahnhof Emmenbrücke Kapf bildet es auf wunderbare Weise eine Verbindung zwischen belebter Umgebung und erholsamer Natur.</p>
          </div>
          <div class="drittesdrittel">
            <p>Wir pflegen einen ehrlichen, wertschätzenden und respektvollen Umgang und begegnen einander auf Augenhöhe. In der Villa Riffig schaffen wir eine positive Atmosphäre und achten darauf, dass sich alle wohl und unterstützt fühlen.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-four">
      <div class="wrapper">
        <div class="titelwrapper">
          <h1>Kontakt</h1>
        </div>
        <div class="spaltenwrapper">
          <div class="erstesdrittel">
          </div>
          <div class="zweitesdrittel">
            <p>Verein Villa Riffig<br />Schür 1<br />6020 Emmen</p>
          </div>
          <div class="drittesdrittel">
            <p>Schreibe uns eine Nachricht:<br />villariffig@gmx.ch</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  // Component logic goes here
};
</script>

<style>
@font-face {
    font-family: 'rfb';
    src: url('~@/assets/fonts/rfb.woff2') format('woff2'),
         url('~@/assets/fonts/rfb.woff') format('woff'),
         url('~@/assets/fonts/rfb.otf') format('otf'),
         url('~@/assets/fonts/rfb.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-size: 16px;
}

.container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

.wrapper {
  padding: 0 2rem;
}

.section {
  scroll-snap-align: start;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: left;
  flex-direction: column;
  text-align: left;
  font-family: 'rfb', sans-serif;
}

.section-one { background-color: #A2D9CE; } /* Mint green */
.section-two { background-color: #F7DC6F; } /* Soft yellow */
.section-three { background-color: #76D7C4; } /* Turquoise */
.section-four { background-color: #F0B27A; } /* Warm peach */

.spaltenwrapper {
  display: flex;
  align-items: left;
  flex-direction: row;
}

.erstesdrittel, .zweitesdrittel, .drittesdrittel {
  flex: 1;
}

.erstesdrittel, .zweitesdrittel {
  padding-right: 2rem;
}

h1 {
  margin: 0;
  font-size: 10.5rem;
  color: #000;
}

p, a {
  font-size: 1.5rem;
  color: #000;
}
</style>
